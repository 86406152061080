<template>
  <div class="notification-page">
    <div class="body-box-rapport">
      <div class="body">
        <div class="table-content">
          <div
            class="ligne-table"
            v-for="not in getAllNotifications"
            :key="not.id"
            @click="showdetailNotification(not)"
          >
            <div class="td-1">
              <div
                class="icon-item mr-3 ml-3"
                :class="{ active: not.seen == false }"
              >
                <font-awesome-icon :icon="not.getIconName()" />
              </div>
              <div>
                <div class="body-item mr-3 ml-3">
                  <span v-html="not.getMessageNotification()"> </span>
                </div>
              </div>
            </div>

            <div class="td-2">
              <div class="time-item  ml-3">{{ not.comment }}</div>
            </div>
            <div class="td-3">
              <div
                class="time-item  ml-3"
                :class="{ active: not.seen == false }"
              >
                il y a {{ not.created_at }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="computedvoirPlus === true"
          class="pagination-notification"
          @click="voirPlus"
        >
          Voir plus
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      page: 1
    };
  },
  methods: {
    ...mapActions([
      'fetchAllNotifications',
      'updateNotification',
      'fetchNewNotification'
    ]),
    showdetailNotification(not) {
      if (not.seen == false) {
        this.updateNotification(not);
      }
      not.showdetailsNotification();
    },
    voirPlus() {
      this.page = this.page + 1;
      this.fetchNewNotification({ page: this.page });
    }
  },
  computed: {
    ...mapGetters(['getAllNotifications', 'gettotalPageNotification']),
    computedvoirPlus() {
      if (this.gettotalPageNotification / 10 > this.page) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.fetchAllNotifications();
  }
};
</script>

<style scoped lang="scss">
.notification-page {
  width: 100%;
  padding: 21px;
  padding-top: 5px;
  .body-box-rapport {
    height: calc(100vh - 68px);
    width: 100%;
    .body {
      width: 100%;
      ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #ffffff;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #7978aa;
        border-radius: 7px;
      }
      .table-content {
        overflow-y: auto;
        height: calc(100vh - 121px);
        .ligne-table {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          margin: 1px 10px;
          padding: 16px 10px;
          &:hover {
            background-color: #ffffffcc;
            border-radius: 11px;
            box-shadow: 0px 1px 5px #ded8d873;
          }
          .td-1 {
            display: flex;
          }
        }

        .icon-item {
          font-size: 18px;
        }
        .body-item {
          font-size: 12px;
          font-family: 'Montserrat', sans-serif;
        }
        .time-item {
          font-size: 12px;
          font-weight: 500;
        }
        .active {
          color: #9799d6;
        }
      }
      .pagination-notification {
        text-align: center;
        color: #5bbbe6;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
